var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { formField: true, horizontal: _vm.horizontal } }, [
    _c("label", { staticClass: "formFieldHeader" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "div",
      { staticClass: "formFieldBody" },
      [
        _vm._t("default"),
        _c(
          "ul",
          { staticClass: "formFieldMessages" },
          _vm._l(_vm.messages, function (message, index) {
            return _c(
              "li",
              {
                key: message + index,
                class: { error: _vm.messages.length > 0 },
              },
              [_vm._v(_vm._s(message))]
            )
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }