<template>
  <div :class="{formField: true, horizontal: horizontal}">
    <label class="formFieldHeader">{{label}}</label>
    <div class="formFieldBody">
      <slot></slot>
      <ul class="formFieldMessages">
        <li 
          v-for="(message, index) of messages" 
          :key="message + index"
          :class="{error: messages.length > 0}"
        >{{message}}</li>
      </ul>      
    </div>
  </div>
</template>

<script>
export default {
  name: 'formField',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    message: String,
    label: String,
    horizontal: Boolean,
    fieldType: String
  },
  components: {},
  mixins: [],
  data: function () {
    return {
      validationErrors: []
    }
  },
  computed: {
    messages(){
      return [this.message, ...this.validationErrors]
    }
  },
  methods: {
    // emitNewValue(value) { this.$emit('change', value) }
  },
  mounted() {},
}
</script>

<style scoped>
.formField {
  display: flex;
  flex-flow: column wrap;
  margin: 10px;
}
.formField.horizontal{
  flex-flow: row wrap;
}
.formFieldHeader {
  font-weight: 700;
}
.formFieldBody {
  display:flex;
  flex-flow: column wrap;
}

</style>
